import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.less']
})
export class UnderConstructionComponent implements OnInit, OnDestroy {
  public href: string = "";
  private sub: Subscription;

  constructor(private route: ActivatedRoute) {
    this.href = document.URL;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      TweenMax.to(window, 0.0, { scrollTo: { y: 0 }, ease: Power1.easeOut })
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
