import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeSharedVideoComponent } from './home/home-shared-video/home-shared-video.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
    isFooterAndHeaderHidden = false;

    constructor(private analytics: AngularFireAnalytics, public activatedRoute: ActivatedRoute) {
        this.activatedRoute.fragment.subscribe(fragment => {
            this.isFooterAndHeaderHidden = document.URL.includes('sharedVideo');
            if (this.isFooterAndHeaderHidden) {
                document.getElementsByTagName('html')[0]?.classList?.add('sharedVideo')
            } else {
                document.getElementsByTagName('html')[0]?.classList?.remove('sharedVideo')
            }
        });
    }

    ngOnInit(): void {
        this.analytics.logEvent('Open Website');
    }
}
