<footer>
    <div class="page-content partners-footerLogo">
        <div class="topBorder"></div>
        <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayout.lt-md="row wrap" fxLayoutGap="0px"
            fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="24px grid" fxLayoutGap.gt-md="64px grid">
            <ng-container *ngFor="let partner of partners; let index = index;">
                <a *ngIf="partner.isShownOnPageFooter" class="brandImage-wrp outOf{{nbPartners}}"
                    [href]="partner?.websiteLink" target="_blank" fxFlex.lt-md="33%" fxLayout="row" fxFlex.lt-sm="50%"
                    fxLayoutAlign.lt-md="end stretch" fxLayoutAlign="center center">
                    <img class="brandImage" [src]="partner?.brandImageURL">
                </a>
            </ng-container>
        </div>
    </div>

    <div class="footer-wrp page-content" style="margin-top: 34px;">
        <div class="linkWrp" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-sm="column"
            fxLayoutAlign.lt-sm="start start" fxLayoutGap.lt-sm="34px">
            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="14px">
                <img src="/assets/brand/echoLogo.svg" class="echoBrandIcon" alt="Echo Brand Logo">
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" class="social-links">
                    <a href="https://www.twitch.tv/echo_esports" target="_blank">
                        <img src="/assets/icons/twitch.png" alt="Twitch Icon">
                    </a>
                    <a href="https://twitter.com/EchoGuild" target="_blank">
                        <img class="rounded" src="/assets/icons/twitter.png" alt="Twitter Icon">
                    </a>
                    <a href="https://discord.com/invite/T5vTbydUSw" target="_blank">
                        <img class="rounded" src="/assets/icons/discord.png" alt="Discord Icon">
                    </a>
                    <a href="https://www.youtube.com/channel/UCUiTgN-8IS9btyZN01ioy_A" target="_blank">
                        <img class="rounded youtube" src="/assets/icons/youtube.png" alt="Youtube Icon">
                    </a>
                    <a href="https://www.linkedin.com/company/echoesports/about/" target="_blank">
                        <img class="rounded linkedin" src="/assets/icons/linkedIn_bw.png" alt="LinkedIn Icon">
                    </a>
                    <a href="https://www.tiktok.com/@echoesports.gg" target="_blank">
                        <img class="rounded tiktok" src="/assets/icons/tiktok_bw.png" alt="TikTok Icon">
                    </a>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="44px" fxLayoutGap.lt-md="24px">
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
                    <div class="subtitle-primary">ECHO</div>
                    <a [routerLink]="['home']" class="text-primary" routerLinkActive="active">Home</a>
                    <a href="https://merch.echoesports.gg" class="text-primary">Shop</a>
                    <a href="https://pickems.echoesports.gg/" class="text-primary">Pick'Ems</a>
                    <a [routerLink]="['partners']" class="text-primary" routerLinkActive="active">Partners</a>
                    <a [routerLink]="['team']" class="text-primary" routerLinkActive="active">Players</a>
                    <a [routerLink]="['streams']" class="text-primary" routerLinkActive="active">Streams</a>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
                    <div class="subtitle-primary">RESOURCES</div>
                    <!-- <a [routerLink]="['jobs']" class="text-primary" routerLinkActive="active">Jobs</a> -->
                    <a [routerLink]="['weakauras']" class="text-primary" routerLinkActive="active">WeakAuras</a>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
                    <div class="subtitle-primary">WHO WE ARE</div>
                    <a [routerLink]="['about']" class="text-primary" routerLinkActive="active">Echo</a>
                    <a [routerLink]="['staff']" class="text-primary" routerLinkActive="active">Staff</a>
                    <a [routerLink]="['contact']" class="text-primary" routerLinkActive="active">Contact</a>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 32px;" fxLayout.lt-sm="column"
            fxLayoutAlign.lt-sm="start start" fxLayoutGap.lt-sm="14px">
            <div class="text-primary" style="opacity: 0.6">Copyright © Echo 2021-2022. All rights reserved</div>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="54px" fxLayoutGap.lt-sm="24px">
                <a [routerLink]="['privacy']" class="subtitle-primary"
                    style="color: #9E9E9E; letter-spacing: 0.055em;">PRIVACY POLICY</a>
                <!-- <a [routerLink]="['home']" class="subtitle-primary"
                    style="color: #9E9E9E; letter-spacing: 0.055em;">TERMS</a> -->
            </div>
        </div>
    </div>
</footer>