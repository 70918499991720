<header>
  <div class="outer-wrp">
    <div class="inner-wrp" fxLayout="row" fxLayoutAlign="space-between center">
      <a class="brand-wrp" fxFlex="24%" [routerLink]="['/home']">
        <img class="brand-img" src="/assets/brand/echoLogo.svg" alt="Echo's brand logo">
      </a>
      <div class="navigation-wrp" fxFlex="50%" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px">
        <a [routerLink]="['/home']" class="navigationLink" routerLinkActive="active">HOME</a>
        <a href="https://merch.echoesports.gg/" target="_blank" class="navigationLink">SHOP</a>
        <!-- <a href="https://pickems.echoesports.gg/" target="_blank" class="navigationLink">PICK'EMS</a> -->
        <a [routerLink]="['/about']" class="navigationLink" routerLinkActive="active">WHO WE ARE</a>
        <a [routerLink]="['/partners']" class="navigationLink" routerLinkActive="active">PARTNERS</a>
        <a [routerLink]="['/streams']" class="navigationLink" routerLinkActive="active">STREAMS</a>
        <a [routerLink]="['/team']" class="navigationLink" routerLinkActive="active">TEAM</a>
        <a [routerLink]="['/coaching']" class="navigationLink coaching" routerLinkActive="active">COACHING</a>
        <a href="https://echogg.ly/apply" target="_blank" class="navigationLink">WORK WITH ECHO</a>
        <!-- <a href="https://givelab.com/rSV95Q/echo-race-to-world-first-giveaway" target="_blank"
                    class="giveaway-btn smallerWidthLinks flex" fxLayout="row" fxLayoutAlign="center center"
                    fxLayoutGap="10px">
                    <img src="/assets/icons/treasureChest_60.png" alt="Treasure chest illustration">
                    <div>GIVEAWAY</div>
                </a> -->
      </div>
      <div class="socials-wrp mediumSizeHidden" fxFlex="24%" fxLayout="row" fxLayoutGap="12px"
        fxLayoutAlign="end center">
        <!-- <a href="https://givelab.com/rSV95Q/echo-race-to-world-first-giveaway" target="_blank"
                    class="giveaway-btn bigWidthLinks" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <img src="/assets/icons/treasureChest_60.png" alt="Treasure chest illustration">
                    <div>GIVEAWAY</div>
                </a> -->
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" class="social-links">
          <a href="https://www.twitch.tv/echo_esports" target="_blank">
            <img src="/assets/icons/twitch.png" alt="Twitch Icon">
          </a>
          <a href="https://twitter.com/EchoGuild" target="_blank">
            <img class="rounded" src="/assets/icons/twitter.png" alt="Twitter Icon">
          </a>
          <a href="https://discord.com/invite/T5vTbydUSw" target="_blank">
            <img class="rounded" src="/assets/icons/discord.png" alt="Discord Icon">
          </a>
          <a href="https://www.youtube.com/channel/UCUiTgN-8IS9btyZN01ioy_A" target="_blank">
            <img class="rounded youtube" src="/assets/icons/youtube.png" alt="Youtube Icon">
          </a>
          <a href="https://www.linkedin.com/company/echoesports" target="_blank">
            <img class="rounded linkedin" src="/assets/icons/linkedIn_bw.png" alt="LinkedIn Icon">
          </a>
          <a href="https://www.tiktok.com/@echoesports.gg" target="_blank">
            <img class="rounded tiktok" src="/assets/icons/tiktok_bw.png" alt="TikTok Icon">
          </a>
        </div>
      </div>
      <div class="menuBtn" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="6px" (click)="openMobileMenu()">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </div>
  </div>
</header>

<div class="smallScreenMenu" [ngClass]="{'visible': isMobileMenuVisible}" fxLayoutAlign="end end">
  <div class="mobile-navigation-wrp" fxFlex="50%" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="32px">
    <a href="https://merch.echoesports.gg" (click)="closeResponsiveMenu()" class="navigationLink">SHOP</a>
    <!-- <a href="https://pickems.echoesports.gg/" (click)="closeResponsiveMenu()" class="navigationLink">PICK'EMS</a> -->
    <a [routerLink]="['/about']" (click)="closeResponsiveMenu()" class="navigationLink">WHO WE ARE</a>
    <a [routerLink]="['/partners']" (click)="closeResponsiveMenu()" class="navigationLink">PARTNERS</a>
    <a [routerLink]="['/streams']" (click)="closeResponsiveMenu()" class="navigationLink">STREAMS</a>
    <a [routerLink]="['/team']" (click)="closeResponsiveMenu()" class="navigationLink">TEAM</a>
    <a [routerLink]="['/coaching']" (click)="closeResponsiveMenu()" class="navigationLink">COACHING</a>
    <a href="https://echogg.ly/apply" (click)="closeResponsiveMenu()" class="navigationLink">WORK WITH ECHO</a>
    <!-- <a href="https://givelab.com/rSV95Q/echo-race-to-world-first-giveaway" (click)="closeResponsiveMenu()"
            target="_blank" class="giveaway-btn flex" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <img src="/assets/icons/treasureChest_60.png" alt="Treasure chest illustration">
            <div>GIVEAWAY</div>
        </a> -->
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" class="social-links">
      <a href="https://www.twitch.tv/echo_esports" (click)="closeResponsiveMenu()" target="_blank">
        <img src="/assets/icons/twitch.png" alt="Twitch Icon">
      </a>
      <a href="https://twitter.com/EchoGuild" (click)="closeResponsiveMenu()" target="_blank">
        <img class="rounded" src="/assets/icons/twitter.png" alt="Twitter Icon">
      </a>
      <a href="https://discord.com/invite/T5vTbydUSw" (click)="closeResponsiveMenu()" target="_blank">
        <img class="rounded" src="/assets/icons/discord.png" alt="Discord Icon">
      </a>
      <a href="https://www.youtube.com/channel/UCUiTgN-8IS9btyZN01ioy_A" (click)="closeResponsiveMenu()"
        target="_blank">
        <img class="rounded youtube" src="/assets/icons/youtube.png" alt="Youtube Icon">
      </a>
    </div>
    <div (click)="closeResponsiveMenu()" class="navigationLink close">CLOSE MENU</div>
  </div>
</div>