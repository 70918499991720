import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {
  partners: BehaviorSubject<PartnerItem[]> = new BehaviorSubject<PartnerItem[]>([]);

  constructor(
    firestore: AngularFirestore
  ) {
    firestore.collection('partners').valueChanges().subscribe(itemsVal => {
      let partners: PartnerItem[] = [];
      itemsVal.forEach(element => {
        let partner = PartnerItem.fromServerObj(element);
        if (partner.status == 'published') {
          partners.push(partner);
        }
      });
      partners.sort(function (a, b) {
        return a.listPosition - b.listPosition;
      });
      this.partners.next(partners);
    });
  }
}

export class PartnerItem {
  brandImageURL: string;
  twitterLink: string;
  facebookLink: string;
  youtubeLink: string;
  instagramLink: string;
  descriptionText: string;
  websiteLink: string;
  websiteText: string;
  isShownOnPageFooter: boolean;
  listPosition: number;
  status: string;
  linkedinLink: string;

  static fromServerObj = (obj: unknown): PartnerItem => {
    let elem = new PartnerItem();
    elem.brandImageURL = obj['logoImage'];
    elem.twitterLink = obj['twitterLink'];
    elem.facebookLink = obj['facebookLink'];
    elem.youtubeLink = obj['youtubeLink'];
    elem.instagramLink = obj['instagramLink'];
    elem.descriptionText = obj['descriptionText'];
    elem.websiteLink = obj['websiteLink'];
    elem.websiteText = obj['websiteLinkButtonText'];
    elem.isShownOnPageFooter = obj['isShownOnPageFooter'];
    elem.listPosition = obj['positionInList'];
    elem.linkedinLink = obj['linkedinLink'];
    elem.status = obj['status'];

    return elem;
  }
}
