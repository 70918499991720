import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const routes: Routes = [
  { path: 'streams', loadChildren: () => import('./streams/streams.module').then(m => m.StreamsModule) },
  { path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'team', loadChildren: () => import('./team/team.module').then(m => m.TeamModule) },
  { path: 'coaching', loadChildren: () => import('./coaching/coaching.module').then(m => m.CoachingModule) },
  { path: 'partners', loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule) },
  { path: 'jobs', loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule) },
  { path: 'staff', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule) },
  { path: 'contact', loadChildren: () => import('./contact-page/contact-page.module').then(m => m.ContactPageModule) },
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'weakauras', loadChildren: () => import('./pages/weakauras/weakauras.module').then(m => m.WeakaurasModule) },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '*', redirectTo: 'home' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    /* scrollPositionRestoration: 'enabled', */
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    scrollOffset: [0, 120]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
