import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
    isMobileMenuVisible = false;

    constructor() { }

    ngOnInit(): void {
    }

    openMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible;
    }

    closeResponsiveMenu() {
        this.isMobileMenuVisible = false;
    }
}
