<div class="partners-wrp page-content" id="partnersList">
    <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="32px">
        <div class="newsContent" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="54px">
            <h2 class="standard-sectionTitle article-title" id="partnersListSection">
                Under Construction
            </h2>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="32px">
                <div class="news-article text-primary">
                    We are reworking this section. It's coming back soon, but feel free to ping us on Discord/Twitter or with an email if
                    you have specific questions.
                </div>
            </div>
        </div>
    </div>
</div>