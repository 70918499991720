<div class="page-content" style="margin-top: 48px">
    <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="start start">
        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h1 class="title-primary">Privacy Policy</h1>
            <div class="text-primary">
                Effective Date: 27-05-2021
                <br /><br />
                Your privacy is important to us<br />
                It is Echo Esports's policy to respect your privacy regarding any information we may collect while
                operating
                our
                website. This Privacy Policy applies to www.echoesports.gg (hereinafter, "us", "we", or
                "www.echoesports.gg").
                We respect your privacy and are committed to protecting personally identifiable information you may
                provide
                us
                through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information
                may
                be
                collected on our Website, how we use this information, and under what circumstances we may disclose the
                information to third parties. This Privacy Policy applies only to information we collect through the
                Website
                and
                does not apply to our collection of information from other sources.
                <br /><br />
                This Privacy Policy, together with the Terms of service posted on our Website, set forth the general
                rules
                and
                policies governing your use of our Website. Depending on your activities when visiting our Website, you
                may
                be
                required to agree to additional terms of service.
            </div>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="tableOfContents">Contents</h2>
            <div class="text-primary tableOfContents">
                Click below to jump to any section of this privacy policy
                <a [routerLink]="['/privacy']" fragment="visitors">Website Visitors</a>
                <a [routerLink]="['/privacy']" fragment="security">Security</a>
                <a [routerLink]="['/privacy']" fragment="link">Links To External Sites</a>
                <a [routerLink]="['/privacy']" fragment="affiliate">Affiliate Disclosure</a>
                <a [routerLink]="['/privacy']" fragment="cookies">Cookies</a>
                <a [routerLink]="['/privacy']" fragment="ecommerce">E-commerce</a>
                <a [routerLink]="['/privacy']" fragment="changes">Privacy Policy Changes</a>
            </div>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="visitors">1. Website Visitors</h2>
            <div class="text-primary">
                Like most website operators, Echo Esports collects non-personally-identifying information of the sort
                that
                web
                browsers and servers typically make available, such as the browser type, language preference, referring
                site,
                and the date and time of each visitor request. Echo Esports's purpose in collecting non-personally
                identifying
                information is to better understand how Echo Esports's visitors use its website. From time to time, Echo
                Esports
                may release non-personally-identifying information in the aggregate, e.g., by publishing a report on
                trends
                in
                the usage of its website.
                <br /><br />
                Echo Esports also collects potentially personally-identifying information like Internet Protocol (IP)
                addresses
                for logged in users and for users leaving comments on <a
                    href="https://www.echoesports.gg">https://www.echoesports.gg</a> blog posts. Echo Esports only
                discloses
                logged in user and commenter IP addresses under the same circumstances that it uses and discloses
                personally-identifying information as described below.
            </div>
            <a [routerLink]="['/privacy']" fragment="tableOfContents">Back to table of contents</a>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="security">2. Security</h2>
            <div class="text-primary">
                The security of your Personal Information is important to us, but remember that no method of
                transmission
                over
                the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
                acceptable
                means to protect your Personal Information, we cannot guarantee its absolute security.
            </div>
            <a [routerLink]="['/privacy']" fragment="tableOfContents">Back to table of contents</a>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="link">3. Links To External Sites</h2>
            <div class="text-primary">
                Our Service may contain links to external sites that are not operated by us. If you click on a third
                party
                link,
                you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and
                terms
                of service of every site you visit.
                <br /><br />
                We have no control over, and assume no responsibility for the content, privacy policies or practices of
                any
                third party sites, products or services.
            </div>
            <a [routerLink]="['/privacy']" fragment="tableOfContents">Back to table of contents</a>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="affiliate">4. Affiliate Disclosure</h2>
            <div class="text-primary">
                This site uses affiliate links and does earn a commission from certain links. This does not affect your
                purchases or the price you may pay.
            </div>
            <a [routerLink]="['/privacy']" fragment="tableOfContents">Back to table of contents</a>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="cookies">5. Cookies</h2>
            <div class="text-primary">
                To enrich and perfect your online experience, Echo Esports uses "Cookies", similar technologies and
                services
                provided by others to display personalized content, appropriate advertising and store your preferences
                on
                your
                computer.
                <br /><br />
                A cookie is a string of information that a website stores on a visitor's computer, and that the
                visitor's
                browser provides to the website each time the visitor returns. Echo Esports uses cookies to help Echo
                Esports
                identify and track visitors, their usage of <a
                    href="https://www.echoesports.gg">https://www.echoesports.gg</a>,
                and their website access preferences. Echo Esports visitors who do not wish to have cookies placed on
                their
                computers should set their browsers to refuse cookies before using Echo Esports's websites, with the
                drawback
                that certain features of Echo Esports's websites may not function properly without the aid of cookies.
                <br /><br />
                By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and
                agree to
                Echo Esports's use of cookies.
            </div>
            <a [routerLink]="['/privacy']" fragment="tableOfContents">Back to table of contents</a>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="ecommerce">6. E-commerce</h2>
            <div class="text-primary">
                Those who engage in transactions with Echo Esports – by purchasing Echo Esports's services or products,
                are
                asked to provide additional information, including as necessary the personal and financial information
                required
                to process those transactions. In each case, Echo Esports collects such information only insofar as is
                necessary
                or appropriate to fulfill the purpose of the visitor's interaction with Echo Esports. Echo Esports does
                not
                disclose personally-identifying information other than as described below. And visitors can always
                refuse to
                supply personally-identifying information, with the caveat that it may prevent them from engaging in
                certain
                website-related activities.
            </div>
            <a [routerLink]="['/privacy']" fragment="tableOfContents">Back to table of contents</a>
        </div>

        <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start start">
            <h2 class="subtitle-primary" id="changes">7. Privacy Policy Changes</h2>
            <div class="text-primary">
                Although most changes are likely to be minor, Echo Esports may change its Privacy Policy from time to
                time,
                and
                in Echo Esports's sole discretion. Echo Esports encourages visitors to frequently check this page for
                any
                changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy
                will
                constitute your acceptance of such change.
            </div>
            <a [routerLink]="['/privacy']" fragment="tableOfContents">Back to table of contents</a>
        </div>
    </div>
</div>