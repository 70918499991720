import { Component, OnInit } from '@angular/core';
import { PartnerItem, PartnersService } from 'src/app/partners/partners.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
    partners: PartnerItem[];
    partnerSize = 100;
    nbPartners = 1;

    constructor(
        private partnersService: PartnersService
    ) {
        this.partnersService.partners.subscribe(partners => {
            let nbItems = 0;
            partners.forEach(partner => {
                if (partner.isShownOnPageFooter) {
                    nbItems += 1;
                }
            });
            if (nbItems > 0) {
                this.partnerSize = 100 / nbItems;
                this.nbPartners = nbItems;
            }
            this.partners = partners;
        });
    }


    ngOnInit(): void {
    }

}
