import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FooterComponent } from './shared/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyAdYauU_aRCOv6pCihiducvGIOegb0otNw",
        authDomain: "echo-27454.firebaseapp.com",
        databaseURL: "https://echo-27454-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "echo-27454",
        storageBucket: "echo-27454.appspot.com",
        messagingSenderId: "202685385025",
        appId: "1:202685385025:web:af4c4d40bc0bf6b9c639c0",
        measurementId: "G-P69YTY7P7C"
    }
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        PrivacyComponent,
        UnderConstructionComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FlexLayoutModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAnalyticsModule,
        BrowserAnimationsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
